<template>
  <!-- Profile dropdown -->
  <Menu v-if="!$route.meta.hideNavbar && this.isLoggedIn" as="div" class="relative py-7 ml-auto">
    <div class="flex items-center">
      <span class="text-sm text-gray-700 mr-3">{{ fullName }}</span>
      <MenuButton
        class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-gray-600"
      >
        <span class="sr-only">Open user menu</span>
        <UserCircleIcon class="h-8 w-8 text-primary" />
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="opacity/0 scale-95"
      enter-to-class="opacity/100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="opacity/100 scale-100"
      leave-to-class="opacity/0 scale-95"
    >
      <MenuItems
        class="absolute z-10 mt-2 w-48 right-0 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      >
        <span class="block px-4 py-3 text-sm text-gray-700 border-b">
          <strong v-for="item in this.userGroups" :key="item.name" class="mr-1">
            {{ item.name }},
          </strong>
        </span>
        <MenuItem v-for="item in userNavigation" :key="item.name">
          <RouterLink
            :to="item.href"
            class="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100"
            >{{ item.name }}</RouterLink
          >
        </MenuItem>

        <a
          id="logout"
          class="block font-normal px-4 py-3 text-sm text-gray-700 hover:bg-gray-100"
          @click.prevent="logout()"
          href="#"
          >Logout</a
        >
      </MenuItems>
    </transition>
  </Menu>

  <nav
    v-if="!$route.meta.hideNavbar && !this.isLoggedIn"
    class="flex justify-end py-7 mx-auto max-w-3xl"
  >
    <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
      <RouterLink
        v-if="$route.name !== 'Home'"
        to="/"
        class="inline-flex items-center text-sm text-gray-900 hover:text-gray-700"
        >Home</RouterLink
      >
      <RouterLink
        v-if="$route.name !== 'Login' && this.isLoggedIn"
        to="/login"
        data-cy="login"
        class="inline-flex items-center text-sm text-gray-900 hover:text-gray-700"
        >Login</RouterLink
      >
    </div>
  </nav>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import GlobalWrapper from '@/components/GlobalWrapper.vue'
import { UserCircleIcon } from '@heroicons/vue/20/solid'

export default {
  name: 'NavMenu',
  components: {
    GlobalWrapper,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    UserCircleIcon,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const userGroups = []

    async function logout() {
      await store.dispatch('setUser', null)
      router.push({ name: 'Home' })
    }

    // Get user groups to display in nav
    if (store.getters.userGroups) {
      for (let i = 0; i < store.getters.userGroups.length; i++) {
        userGroups.push({ name: store.getters.userGroups[i], visible: false })
      }
    }

    const userNavigation = [
      {
        name: 'Dashboard',
        href: '/campaigns',
      },
    ]

    return {
      userNavigation,
      isLoggedIn: computed(() => store.getters.isLoggedIn),
      logout,
      userGroups,
      fullName: computed(() => store.getters.user.fullName),
    }
  },
}
</script>
