import { z } from 'zod'
import { baseModelShape } from '../baseModel'

export const BBMS = 'BBMS'
export const EveryAction = 'EveryAction'
export const SpringBoard = 'SpringBoard'
export const EngagingNetwork = 'EngagingNetwork'

export const paymentProviders = {
  1: BBMS,
  2: EveryAction,
  3: SpringBoard,
  4: EngagingNetwork,
}

export const npoShape = {
  id: z.string().uuid(),
  name: z.string(),
  logo: z.string().url().nullable(),
}

export const answerShape = {
  id: z.string().uuid(),
  text: z.string(),
}
