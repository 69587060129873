<template>
  <DashboardContainer :pageTitle="pageTitle">
    <Spinner v-if="loading" class="mt-24" />
    <template v-else>
      <div class="flex flex-col gap-4 mb-4">
        <div class="flex flex-wrap gap-4">
          <div class="flex items-center gap-2 flex-grow">
            <label for="start" class="text-gray-700">Start Date:</label>
            <input
              id="start"
              name="start"
              type="date"
              class="input--text--small placeholder:text-gray-400 date-input flex-grow"
              placeholder="Select date start"
              :value="selectedDate.date1"
              @input="getDateRangeOption1($event.target.value)"
            />
          </div>
          <div class="flex items-center gap-2 flex-grow">
            <label for="end" class="text-gray-700">End Date:</label>
            <input
              id="end"
              name="end"
              type="date"
              class="input--text--small placeholder:text-gray-400 date-input flex-grow"
              placeholder="Select date end"
              :value="selectedDate.date2"
              @input="getDateRangeOption2($event.target.value)"
            />
          </div>
        </div>
        <div class="flex flex-wrap gap-2">
          <button
            @click="exportCampaignData"
            class="btn--table--export flex-1 max-w-full mb-2 btn-campaign-export"
          >
            Export Day's Campaign Data
          </button>
          <button
            @click="exportCampaignData"
            class="btn--table--export flex-1 max-w-full mb-2 btn-campaign-export"
          >
            Export Data by Date Range
          </button>
        </div>
      </div>

      <!-- Campaign Data (mobile) -->
      <div class="md:hidden">
        <h3 class="title font-bold">Assigned Fundraisers</h3>
        <div class="shadow md:hidden rounded -mt-2 mb-5">
          <ul
            role="list"
            class="mt-2 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden"
            v-if="tableData.length"
          >
            <li v-for="(i, key) in tableData" :key="key">
              <div class="px-4 pt-4 truncate">
                <div class="flex flex-row justify-between">
                  <strong class="block truncate">{{ i.fundraiser_full_name }}</strong>
                  <button
                    class="btn--table -mt-1"
                    @click="removeFundraiserFromCampaign(i.fundraiser)"
                  >
                    Remove
                  </button>
                </div>
                <div class="flex flex-col mt-2">
                  <div class="flex flex-row justify-between">
                    <p>Donations:</p>
                    <p>{{ i.success_donation_count }}</p>
                  </div>
                  <div class="flex flex-row justify-between mb-2">
                    <p>Leads:</p>
                    <p>{{ i.lead_count }}</p>
                  </div>
                  <div class="flex flex-row justify-between mb-2">
                    <p>Worked Hours:</p>
                    <input
                      type="number"
                      :value="i.worked_hours"
                      @change="handleWorkedHoursChange(i.id, $event)"
                      class="input--text--small d-input"
                      placeholder="Enter worked hours"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <p v-else class="mt-2 p-4 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden">
            There are currently no fundraisers assigned to this campaign.
          </p>
        </div>
      </div>
      <div class="md:hidden">
        <h3 class="title font-bold">Available Fundraisers</h3>
        <div class="shadow rounded -mt-2">
          <ul
            role="list"
            class="mt-2 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden"
            v-if="availableFundraisers.length"
          >
            <li v-for="(fundraiser, key) in availableFundraisers" :key="key">
              <div class="px-4 pt-4 truncate">
                <div class="flex flex-row justify-between">
                  <strong class="block truncate">{{ fundraiser.fullName }}</strong>
                  <button class="btn--table -mt-1" @click="assignFundraiserToCampaign(fundraiser)">
                    Assign
                  </button>
                </div>
                <p class="block truncate mb-2 text-xs">{{ fundraiser.email }}</p>
                <p class="block truncate mb-2 text-xs">
                  {{ fundraiser.homeOffice ? fundraiser.homeOffice.name : 'N/A' }}
                </p>
              </div>
            </li>
          </ul>
          <p v-else class="mt-2 p-4 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden">
            There are currently no available fundraisers to assign to this campaign.
          </p>
        </div>
      </div>
      <!-- Campaign Data (desktop) -->
      <div class="hidden md:block">
        <div class="mt-2 flex flex-col">
          <div class="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr class="bg-gray-50 rounded-tl-lg rounded-tr-lg">
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Assigned Fundraisers
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Donations
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Leads
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Worked Hours
                  </th>
                  <th scope="col" class="bg-gray-50 px-6 py-3">
                    <span class="invisible">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <template v-if="tableData.length">
                  <tr v-for="i in tableData" :key="i.id">
                    <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                      {{ i.fundraiser_full_name }}
                    </td>
                    <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                      {{ i.success_donation_count }}
                    </td>
                    <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                      {{ i.lead_count }}
                    </td>
                    <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                      <input
                        type="number"
                        :value="i.worked_hours"
                        @change="handleWorkedHoursChange(i.id, $event)"
                        class="input--text--small d-input"
                        placeholder="Enter worked hours"
                      />
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm font-semibold">
                      <button
                        class="btn--table"
                        @click="removeFundraiserFromCampaign(i.fundraiser)"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="5" class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                    There are currently no fundraisers assigned to this campaign.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="hidden md:block mt-12">
        <div class="mt-2 flex flex-col">
          <div class="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr class="bg-gray-50 rounded-tl-lg rounded-tr-lg">
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Available Fundraisers
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Email
                  </th>
                  <!-- New column for Email -->
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Home Office
                  </th>
                  <!-- New column for Home Office -->
                  <th scope="col" class="bg-gray-50 px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <template v-if="availableFundraisers.length">
                  <tr v-for="fundraiser in availableFundraisers" :key="fundraiser.id">
                    <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                      {{ fundraiser.fullName }}
                    </td>
                    <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                      {{ fundraiser.email }}
                    </td>
                    <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                      {{ fundraiser.homeOffice ? fundraiser.homeOffice.name : 'N/A' }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm font-semibold">
                      <button class="btn--table" @click="assignFundraiserToCampaign(fundraiser)">
                        Assign
                      </button>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="2" class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                    There are currently no available fundraisers to assign to this campaign.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <RouterLink :to="{ name: 'CampaignList' }" class="btn--table mt-10">
          Back to Campaigns
        </RouterLink>
      </div>
    </template>
  </DashboardContainer>
</template>

<script>
import { onBeforeMount, ref, triggerRef, reactive, watch, camelize } from 'vue'
import { useRoute } from 'vue-router'
import DashboardContainer from '../components/DashboardContainer.vue'
import Spinner from '@/components/Spinner'
import { CampaignApi } from '@/services/campaign/'
import { fundraiserFunctions } from '@/services/users/'
import { WorkedHoursApi } from '@/services/workingHours'
import { makeCSV } from '../services/workingHours/daily_working_stats_csv'
export default {
  name: 'CampaignData',
  components: {
    DashboardContainer,
    Spinner,
  },
  setup() {
    const route = useRoute()
    const campaignId = route.params.id
    const campaign = ref()
    const { fundraiserCollection } = fundraiserFunctions()
    const fundraisers = ref(fundraiserCollection)
    const assignedFundraisers = ref([])
    const availableFundraisers = ref([])
    const hours = ref()
    const tableData = ref([])
    const loading = ref(false)
    const workedHoursMap = reactive({})
    const pageTitle = ref('')

    onBeforeMount(async () => {
      loading.value = true
      try {
        campaign.value = await CampaignApi.csc.customRetrieve({
          campaign: campaignId,
        })
        pageTitle.value = campaign.value.location
          ? campaign.value.name + ' ' + campaign.value.location + ' Campaign Data'
          : campaign.value.name + ' ' + 'Campaign Data'
        await fundraisers.value.refresh().catch((error) => {
          alert(error)
        })
        assignedFundraisers.value = campaign.value.fundraisers
        availableFundraisers.value = filterAvailableFundraisers(fundraisers.value.list)
        hours.value = await fetchAndLogWorkedHours()
        updateWorkedHoursMap()
        tableData.value = hours.value.results
      } catch (e) {
        console.log(e)
      }
      triggerRef(fundraisers)
      triggerRef(hours)
      loading.value = false
    })

    const fetchAndLogWorkedHours = async () => {
      try {
        const response = await WorkedHoursApi.csc.customRetrieve({
          campaign: campaignId,
          date: selectedDate.date1,
        })
        return response
      } catch (error) {
        console.error('Error fetching worked hours:', error)
      }
    }

    // Filter fundraisers based on if they are not currently assigned to the current campaign
    // BB note: This will be regardless of if they're assigned to other campaigns so
    // this may need to be changed to only grab fundraisers that are assigned to no campaigns
    const filterAvailableFundraisers = (fundraisers) => {
      return fundraisers.filter(
        (fundraiser) =>
          !fundraiser.campaigns.some((campaign) => campaign.id === campaignId) &&
          fundraiser.isActive,
      )
    }

    const removeFundraiserFromCampaign = async (fundraiser) => {
      let updatedAssignedFundraisers = assignedFundraisers.value.filter(
        (fund) => fund.id !== fundraiser,
      )
      let assignedFundraiserIds = updatedAssignedFundraisers.map((fund) => fund.id)
      let updatedCampaign = {
        id: campaignId,
        fundraisers: assignedFundraiserIds,
      }
      try {
        campaign.value = await CampaignApi.csc.customUpdate(updatedCampaign)
        await fundraisers.value.refresh().catch((error) => {
          alert(error)
        })
        window.location.reload()
      } catch (e) {
        console.log(e)
      }
      triggerRef(fundraisers)
      triggerRef(hours)
      assignedFundraisers.value = updatedAssignedFundraisers
      availableFundraisers.value = filterAvailableFundraisers(fundraisers.value.list)
    }

    const assignFundraiserToCampaign = async (fundraiser) => {
      let updatedAssignedFundraisers = assignedFundraisers.value
      updatedAssignedFundraisers.push(fundraiser)
      let assignedFundraiserIds = updatedAssignedFundraisers.map((fund) => fund.id)
      let updatedCampaign = {
        id: campaignId,
        fundraisers: assignedFundraiserIds,
      }
      try {
        campaign.value = await CampaignApi.csc.customUpdate(updatedCampaign)
        await fundraisers.value.refresh().catch((error) => {
          alert(error)
        })
        window.location.reload()
      } catch (e) {
        console.log(e)
      }
      triggerRef(fundraisers)
      triggerRef(hours)
      assignedFundraisers.value = updatedAssignedFundraisers
      availableFundraisers.value = filterAvailableFundraisers(fundraisers.value.list)
    }

    const updateWorkedHoursMap = () => {
      assignedFundraisers.value.forEach((fundraiser) => {
        workedHoursMap[fundraiser.id] = getWorkedHours(fundraiser)
      })
    }

    const updateWorkedHours = async (workedHourId, newWorkedHours) => {
      try {
        const oldWorkedHours = getOutdatedWorkedHour(workedHourId)

        const updatedWorkedHours = oldWorkedHours + newWorkedHours

        const updatePayload = {
          id: workedHourId,
          worked_hours: updatedWorkedHours,
        }

        await WorkedHoursApi.csc.customUpdate(updatePayload)

        updateWorkedHoursMap()
      } catch (e) {
        console.log(e)
      }
    }

    const getOutdatedWorkedHour = (id) => {
      const list = hours.value.results
      const outdatedWorkedHourObj = list.find((obj) => obj.id === id)
      return outdatedWorkedHourObj ? parseFloat(outdatedWorkedHourObj.workedHours || 0) : 0
    }

    const handleWorkedHoursChange = async (workedHourId, event) => {
      const newWorkedHours = parseFloat(event.target.value) || 0
      workedHoursMap[workedHourId] = newWorkedHours
      await updateWorkedHours(workedHourId, newWorkedHours)
    }

    const getDailyWorkedHours = (fundraiser) => {
      const filteredWorkedHours = hours.value.results
      return filteredWorkedHours.filter(
        (hour) => hour.fundraiser === fundraiser.id && hour.campaign === campaignId,
      )
    }

    const getWorkedHours = (fundraiser) => {
      const list = getDailyWorkedHours(fundraiser)

      return list.reduce((total, hour) => total + parseFloat(hour.worked_hours || 0), 0)
    }
    const formatDateQueryParam = (date) => {
      return new Date(date).toISOString().split('T')[0]
    }
    const today = new Date().toISOString().split('T')[0]
    const selectedDate = reactive({
      date1: today,
      date2: '',
    })

    const getDateRangeOption1 = (date) => {
      if (date) {
        date = date.replace(/-/g, '/')
        selectedDate.date1 = formatDateQueryParam(date)
      } else {
        selectedDate.date1 = ''
      }
    }

    const getDateRangeOption2 = (date) => {
      if (date) {
        date = date.replace(/-/g, '/')
        selectedDate.date2 = formatDateQueryParam(date)
      } else {
        selectedDate.date2 = ''
      }
    }
    const exportCampaignData = async () => {
      if (!selectedDate.date1 || !selectedDate.date2) {
        makeCSV(hours.value)
      } else {
        let startDate = new Date(selectedDate.date1)
        let endDate = new Date(selectedDate.date2)
        if (startDate > endDate) {
          console.warn('Start date is later than end date. Swapping dates.')[(startDate, endDate)] =
            [endDate, startDate]
        }
        const formattedStartDate = startDate.toISOString().split('T')[0]
        const formattedEndDate = endDate.toISOString().split('T')[0]

        try {
          const response = await WorkedHoursApi.csc.rangeRetrieve({
            campaign: campaignId,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
          })
          makeCSV(response)
        } catch (error) {
          console.error('Error exporting range data:', error)
        }
      }
    }
    watch(
      () => selectedDate.date1,
      async (newDate) => {
        updateWorkedHoursMap()
        hours.value = await fetchAndLogWorkedHours()
        tableData.value = hours.value.results
      },
    )

    return {
      campaign,
      assignedFundraisers,
      availableFundraisers,
      loading,
      removeFundraiserFromCampaign,
      assignFundraiserToCampaign,
      getDateRangeOption1,
      getDateRangeOption2,
      selectedDate,
      getWorkedHours,
      workedHoursMap,
      updateWorkedHours,
      handleWorkedHoursChange,
      fetchAndLogWorkedHours,
      tableData,
      exportCampaignData,
      pageTitle,
    }
  },
}
</script>
